/* Sidebar */

.sidebar {
    min-height: calc(100vh - #{$navbar-height});
    background: $sidebar-dark-bg;
    font-family: $type-1;
    padding: 0;
    width: $sidebar-width-lg;
    z-index: 999;
    padding-bottom: 40px;
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    .sidebar-brand-wrapper {
        width: auto;
        height: $navbar-height;
        .sidebar-brand {
            // padding: 2.25rem 0 0.25rem 40px;
            text-align: center;
            display: inline-block;
            margin: 0 auto;
            .rtl & {
                padding: 20px 61px 10px 0;
            }
            img {
                width: calc(#{$sidebar-width-lg} - 130px);
                max-width: 100%;
                height: 48px;
                margin: auto;
                vertical-align: middle;
            }
        }
        .brand-logo-mini {
            display: none;
            img {
                width: calc(#{$sidebar-width-icon} - 50px);
                max-width: 100%;
                height: 35px;
                margin: auto;
            }
        }
    }
    .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        .nav-item {
            .collapse {
                z-index: 999;
            }
            .collapse.show,
            .collapsing {
                background: $sidebar-light-menu-active-bg;
            }
            .nav-link {
                align-items: center;
                display: flex;
                padding: $sidebar-menu-padding;
                white-space: nowrap;
                height: $nav-link-height;
                color: $sidebar-menu-color;
                span {
                    &.menu-arrow {
                        margin-left: auto;
                        margin-right: 0;
                        transition-duration: 0.2s;
                        transition-property: transform;
                        transition-timing-function: ease-in;
                        &:before {
                            content: "\F231";
                            font-family: "Bootstrap Icons";
                            font-size: 18px;
                            line-height: 1;
                            font-style: normal;
                            vertical-align: middle;
                            //color: $sidebar-menu-color;
                        }
                    }
                }
                &[aria-expanded="true"] {
                    background: $sidebar-light-menu-active-bg;
                    color: $sidebar-dark-bg;
                    span {
                        &.menu-arrow {
                            transform: rotate(90deg);
                        }
                    }
                }
                .menu-icon {
                    margin-right: 1.25rem;
                    width: $sidebar-icon-size;
                    line-height: 1;
                    font-size: 18px;
                    //color: $sidebar-menu-color;
                    .rtl & {
                        margin-right: 0;
                        margin-left: 1.25rem;
                    }
                }
                .menu-title {
                    //color: $sidebar-menu-color;
                    display: inline-block;
                    font-size: $sidebar-menu-font-size;
                    line-height: 1;
                    vertical-align: middle;
                    cursor: pointer;
                }
                .badge {
                    margin-left: auto;
                }
                &:hover {
                    color: $sidebar-dark-bg;
                }
            }
            &.active {
                >.nav-link {
                    color: $sidebar-menu-color;
                    .menu-title,
                    .menu-arrow {
                        color: $sidebar-menu-color;
                    }
                }
            }
            &.nav-profile {
                .nav-link {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    .user-wrapper {
                        display: flex;
                        margin-bottom: 30px;
                        position: relative;
                        .profile-image {
                            width: 40px;
                            height: 40px;
                            img {
                                border-radius: 100%;
                                max-width: 100%;
                            }
                        }
                        .text-wrapper {
                            margin-left: 15px;
                            .rtl & {
                                margin-left: 0;
                                margin-right: 15px;
                            }
                            .profile-name {
                                font-weight: 500;
                                margin-bottom: 7px;
                                line-height: 1;
                            }
                            .designation {
                                margin-right: 3px;
                            }
                        }
                    }
                    .dropdown {
                        .dropdown-toggle {
                            &.arrow-hide {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                    .dropdown {
                        position: static;
                        .user-switch-dropdown-toggler {
                            display: flex;
                            flex-direction: row;
                            padding: 1px 0px;
                            &:focus {
                                outline: 0;
                                box-shadow: none;
                            }
                        }
                        .dropdown-menu {
                            top: auto !important;
                            padding: 0;
                        }
                    }
                }
            }
            &.not-navigation-link {
                position: relative;
            }
        }
        &:not(.sub-menu) {
            >.nav-item {
                &:hover {
                    &:not(.nav-profile) {
                        >.nav-link {
                            background: $sidebar-light-menu-hover-bg;
                        }
                    }
                }
            }
        }
        &.sub-menu {
            margin-bottom: 0;
            padding: $sidebar-submenu-padding;
            .nav-item {
                .nav-link {
                    color: $sidebar-dark-bg;
                    padding: $sidebar-submenu-item-padding;
                    font-size: $sidebar-submenu-font-size;
                    line-height: 1;
                    height: auto;
                }
                &.active {
                    .nav-link {
                        color: $sidebar-light-menu-active-bg;
                        background: transparent;
                    }
                }
                &:hover {
                    >.nav-link {
                        background: $sidebar-light-submenu-hover-bg;
                        color: $sidebar-menu-color;
                        &:before {
                            background: $sidebar-light-submenu-hover-color;
                        }
                    }
                }
            }
        }
    }
}

//sidebar color variation
.sidebar-dark {
    .sidebar {
        background: $sidebar-dark-bg;
        .nav {
            .nav-item {
                .collapse.show,
                .collapsing {
                    background: $sidebar-dark-menu-active-bg;
                }
                .nav-link {
                    color: $sidebar-menu-color;
                    &[aria-expanded="true"] {
                        background: $sidebar-dark-menu-active-bg;
                        color: $sidebar-dark-bg;
                    }
                    i {
                        color: $sidebar-menu-color;
                        &.menu-arrow {
                            &:before {
                                color: $sidebar-dark-menu-arrow-color;
                            }
                        }
                    }
                    &:hover {
                        color: $sidebar-dark-bg;
                    }
                }
                &.nav-profile {
                    .profile-name {
                        color: $sidebar-dark-profile-name-color;
                    }
                    .designation {
                        color: $sidebar-dark-profile-title-color;
                    }
                    .notification-panel {
                        &:before {
                            background: $sidebar-dark-profile-name-color;
                        }
                        >span {
                            background: $sidebar-dark-menu-active-bg;
                            i {
                                color: color(gray-light);
                            }
                        }
                    }
                }
                &.active {
                    >.nav-link {
                        color: $sidebar-dark-menu-active-color;
                    }
                }
                .sidebar-sticker {
                    background: $sidebar-dark-menu-active-bg;
                }
            }
            &:not(.sub-menu) {
                >.nav-item {
                    &:hover {
                        &:not(.nav-profile) {
                            >.nav-link {
                                background: $sidebar-dark-menu-hover-bg;
                                color: $sidebar-dark-bg;
                            }
                        }
                    }
                }
            }
            &.sub-menu {
                .nav-item {
                    .nav-link {
                        color: $sidebar-dark-submenu-color;
                        &.active {
                            color: $sidebar-dark-bg;
                            &:before {
                                background: $sidebar-dark-menu-active-color;
                            }
                        }
                    }
                    &:hover {
                        >.nav-link {
                            background: $sidebar-dark-submenu-hover-bg;
                            color: $sidebar-light-menu-active-bg;
                            &:before {
                                background: $sidebar-dark-submenu-hover-color;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - #{$navbar-height});
        top: $navbar-height;
        bottom: 0;
        overflow: auto;
        right: -$sidebar-width-lg;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
        &.active {
            right: 0;
        }
    }
}
