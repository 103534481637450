////////// COLOR SYSTEM //////////
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray: #2e383e;
$dodger-blue: #3498db;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$grays: ( "100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, "900": $gray-900) !default;
$colors: ( "blue": $blue, "indigo": $indigo, "purple": $purple, "pink": $pink, "danger": $red, "orange": $orange, "yellow": $yellow, "green": $green, "teal": $teal, "cyan": $cyan, "black": $black, "white": $white, "gray": $gray-600, "gray-dark": $gray-800) !default;
$black: #000 !default;
$blue-teal-gradient: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
$blue-teal-gradient-light: linear-gradient( 120deg, rgba(0, 228, 208, 0.7), rgba(89, 131, 232, 0.7));
$theme-colors: ( 'primary': #2196f3, 'secondary': #dde4eb, 'success': #19d895, 'info': #8862e0, 'warning': #ffaf00, danger: #ff0017, 'light': #fbfbfb, 'dark': #252c46);
$theme-gradient-colors: (' primary': linear-gradient(to right, #da8cff, #9a55ff), 'secondary': linear-gradient(to right, #e7ebf0, #868e96), 'success': linear-gradient(to right, #84d9d2, #07cdae), 'info': linear-gradient(to right, #90caf9, #047edf 99%), 'warning': linear-gradient(to right, #f6e384, #ffd500), 'danger': linear-gradient(to right, #ffbf96, #fe7096), 'light': linear-gradient(to bottom, #f4f4f4, #e4e4e9), 'dark': linear-gradient(89deg, #5e7188, #3e4b5b));
////////// COLOR SYSTEM //////////
////////// COLOR VARIABLES //////////
$content-bg: #f3f4fa;
$footer-bg: $content-bg;
$footer-color: color(dark);
$border-color: #dee2e6;
$circle-border: $white;
////////// COLOR VARIABLES //////////
////////// SOCIAL COLORS //////////
$social-colors: ( twitter: #2caae1, facebook: #3b579d, google: #dc4a38, linkedin: #0177b5, pinterest: #cc2127, youtube: #e52d27, github: #333333, behance: #1769ff, dribbble: #ea4c89, reddit: #ff4500);
////////// SOCIAL COLORS //////////
////////// FONTS//
$type-1: "Roboto",
sans-serif;
$default-font-size: 0.875rem; // 14px as base font size
$text-muted: #6c7293;
$body-color: #343a40;
////////// FONT VARIABLES //////////
////////// SIDEBAR ////////
$sidebar-width-lg: 255px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 100px;
$sidebar-light-bg: #fff;
$sidebar-light-menu-color: #4a4a4a;
$sidebar-dark-menu-color-hover: #01040d;
$sidebar-menu-color: rgb(239, 236, 236);
$sidebar-light-submenu-color: $sidebar-light-menu-color;
$sidebar-light-menu-active-bg: #fafbfc;
$sidebar-light-menu-active-color: $gray-900;
$sidebar-light-menu-hover-bg: $sidebar-light-menu-active-bg;
$sidebar-light-menu-hover-color: $sidebar-light-menu-color;
$sidebar-light-submenu-hover-bg: initial;
$sidebar-light-submenu-hover-color: #000;
$sidebar-light-icon-active-color: theme-color(success);
$sidebar-light-menu-icon-color: $sidebar-light-menu-color;
$sidebar-light-menu-arrow-color: #9e9da0;
$sidebar-light-menu-border-color: #c0bfcf;
$sidebar-light-category-color: #999999;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;
$sidebar-dark-bg: #1a202c;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: #2e3657;
$sidebar-dark-menu-active-color: darken($white, 30%);
$sidebar-dark-menu-hover-bg: $sidebar-dark-menu-color-hover;
$sidebar-dark-menu-hover-color: color(white);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color-hover;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 30%);
$sidebar-dark-icon-bg: rgba(194, 244, 219, 0.12);
$sidebar-dark-icon-color: #33c92d;
$sidebar-dark-action-border: rgba(151, 151, 151, 0.27);
$sidebar-dark-menu-icon-color: #9f9ea3;
$sidebar-dark-menu-arrow-color: #9f9ea3;
$sidebar-dark-profile-name-color: $sidebar-dark-menu-color;
$sidebar-dark-profile-title-color: #8d9498;
$sidebar-menu-font-size: 15px;
$sidebar-menu-padding-y: 12px;
$sidebar-menu-padding-x: 2.25rem;
$sidebar-menu-padding: 16px 35px;
$sidebar-icon-size: 15px;
$sidebar-submenu-font-size: 0.9rem;
$sidebar-submenu-item-padding: 0.75rem 1rem 0.75rem 1rem;
$sidebar-submenu-padding: 0 0 0 4rem;
$sidebar-icon-font-size: 18px;
$sidebar-arrow-font-size: 0.625rem;
$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0.75rem 1.625rem 0.75rem 1.188rem;
$sidebar-mini-menu-padding: 0.8125rem 1rem 0.8125rem 1rem;
$sidebar-icon-only-menu-padding: 0.5rem 1.625rem 0.5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;
$icon-only-collapse-width: 240px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;
///////// SIDEBAR ////////
///////// NAVBAR ////////
$navbar-height: 63px;
$navbar-bg: linear-gradient(120deg, #2f78e9, #128bfc, #18bef1);
$navbar-bg-2: $white;
$navbar-menu-color: #fff;
$navbar-font-size: 15px;
$navbar-icon-font-size: 1.25rem;
$navbar-brand-bg: #1a202c;
$navbar-toggler-color: #8e94a9;
$navbar-search-bg: #eef0fa;
$navbar-search-icon-color: #8e94a9;
$navbar-count-indicator-bg: #ff0017;
$nav-dropdown-color: #111111;
$navbar-item-bg: #3f3f40;
$navbar-item-light-bg: #e6e5f2;
$light-color-icon-bg: #f2f2f2;
$nav-link-height: 52px;
$navbar-brand-height: 28px;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * .5;
///////// NAVBAR ////////
///////// BUTTONS ////////
$button-fixed-width: 120px;
$btn-padding-y: 0.4rem;
$btn-padding-x: 1rem;
$btn-line-height: 1;
$btn-padding-y-xs: 0.5rem;
$btn-padding-x-xs: 0.75rem;
$btn-padding-y-sm: 0.3rem;
$btn-padding-x-sm: 0.95rem;
$btn-padding-y-lg: 0.94rem;
$btn-padding-x-lg: 1.94rem;
$btn-font-size: 0.875rem;
$btn-font-size-xs: 0.625rem;
$btn-font-size-sm: 0.875rem;
$btn-font-size-lg: 0.875rem;
$btn-border-radius: 0.1875rem;
$btn-border-radius-xs: 0.1875rem;
$btn-border-radius-sm: 0.1875rem;
$btn-border-radius-lg: 0.1875rem;
$social-btn-padding: 13px;
$social-btn-icon-size: 1rem;
///////// BUTTONS ////////
////////// TOOLTIP VARIABLES //////////
//default styles
$tooltip-font-size: 0.75rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.75rem;
$tooltip-border-radius: 0.375rem;
////////// TOOLTIP VARIABLES //////////
/////////  FORMS /////////
$input-bg: color(white);
$input-border-width: 1px;
$input-border-radius: 2px;
$input-placeholder-color: #b9b8b8;
$input-font-size: 0.75rem;
$input-padding-y: 0.875rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;
$input-padding-y-xs: 0.5rem;
$input-padding-x-xs: 0.75rem;
$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.81rem;
$input-padding-y-lg: 0.94rem;
$input-padding-x-lg: 1.94rem;
$input-height: 2rem;
$input-height-sm: 1.75rem;
$input-height-lg: 3.312rem;
///////// FORMS /////////
////////  DROPDOWNS ///////
$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
////////  DROPDOWNS ///////
//////// TABLES ////////
$table-accent-bg: rgba(0, 0, 0, 0.05);
$table-hover-bg: $content-bg;
$table-cell-padding: 0.9375rem;
$table-border-color: $border-color;
$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);
//////// TABLES ////////
////////// MEASUREMENT AND PROPERTY VARIABLES //////////
$card-bg: #fff;
$card-title-color: $body-color;
$border-property: 1px solid $border-color;
$border-property: 1px solid $border-color;
$card-spacing-y: 25px;
$card-padding-y: 1.88rem;
$card-padding-x: 1.81rem;
$card-border-radius: 3px;
$card-border-color: #e6e6e6;
$grid-gutter-width: 25px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
$card-description-color: #76838f;
$card-bg-varient: #fff;
$card-shadow-color: 0px 0px 8px 6px rgba(0, 0, 0, 0.02);
$list-card-bg: $white;
////////// OTHER VARIABLES //////////
////////// BREAD CRUMBS VARIABLES //////////
// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";
// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;
////////// BREAD CRUMBS VARIABLES //////////
////////// MODALS VARIABLES //////////
$border-width: border-width 1px;
$modal-inner-padding: 0.937rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-bg: $content-bg;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5);
$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;
$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;
$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;
$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;
$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;
$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform 0.4s ease;
////////// MODALS VARIABLES //////////
/////////  TABS VARIABLES //////////
$nav-tabs-border-color: #ebedf2;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
$text-gray: gray;
$circle-img-border: $white;
////////   TABS VARIABLES /////////
////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////
///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////
////////// Vendor file asset path //////////
$mdi-font-path: "~@mdi/font/fonts";
$fa-font-path: "~font-awesome/fonts/";
$flag-icon-css-path: "~flag-icon-css/flags/";
$simple-line-font-path: "~simple-line-icons/fonts/";
///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////
///////// TICKETS-TAB ///////
$tab-head-bg: darken(#f2edf3, 2%);
$accent-color: $white;
///////// TICKETS-TAB ///////
///////// LANDING PAGE ///////
$landing-bg: #1a142dfa;
$landing-bg-color: $white;
///////// LANDING PAGE ///////
///////// CHATS ///////
$chat-container-height: 100%;
$chat-bottom-btn: $white;
$form-focus-bg: $white;
///////// CHATS ///////
///////// KANBAN ///////
$private-btn-bg: #eae2ec;
///////// KANBAN ///////
$bullet-line-list-shape-bg: color(white) !default;
$card-mixed-danger: linear-gradient(to bottom, #d41459, #911a6c);
$survey-head-color: #a7afb7;
$list-border-bg: #e8e8e8;
$accordian-head-color: $black;
$datepicker-arrow-bg: #eef1f2;
$body-text-color: $body-color;
