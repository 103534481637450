/* Utilities */

.grid-margin {
    margin-bottom: $card-spacing-y;
}

.grid-margin-sm-0 {
    @media (min-width: 576px) {
        margin-bottom: 0;
    }
}

.grid-margin-md-0 {
    @media (min-width: 768px) {
        margin-bottom: 0;
    }
}

.grid-margin-lg-0 {
    @media (min-width: 992px) {
        margin-bottom: 0;
    }
}

.grid-margin-xl-0 {
    @media (min-width: 1200px) {
        margin-bottom: 0;
    }
}

.grid-margin-md {
    @media (min-width: 768px) {
        margin-bottom: $card-spacing-y;
    }
}

.grid-margin-lg {
    @media (min-width: 992px) {
        margin-bottom: $card-spacing-y;
    }
}

.grid-margin-xl {
    @media (min-width: 1200px) {
        margin-bottom: $card-spacing-y;
    }
}

.img-lg {
    width: 92px;
    height: 92px;
}

.img-sm {
    width: 43px;
    height: 43px;
}

.img-md {
    width: 35px;
    height: 35px;
}

.img-xs {
    width: 32px;
    height: 32px;
}

.img-ss {
    width: 26px;
    height: 26px;
}

.stretch-card {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    >.card {
        width: 100%;
        min-width: 100%;
    }
}

.border-right-sm {
    @media (min-width: 576px) {
        border-right: $border-width solid $border-color;
    }
}

.border-right-md {
    @media (min-width: 768px) {
        border-right: $border-width solid $border-color;
    }
}

.border-right-lg {
    @media (min-width: 992px) {
        border-right: $border-width solid $border-color;
    }
}

.border-left-sm {
    @media (min-width: 576px) {
        border-left: $border-width solid $border-color;
    }
}

.border-left-md {
    @media (min-width: 768px) {
        border-left: $border-width solid $border-color;
    }
}

.border-left-lg {
    @media (min-width: 992px) {
        border-left: $border-width solid $border-color;
    }
}

.text-gray {
    color: #212529;
}

.text-black {
    color: $black;
}

.text-small,
small {
    font-size: 12px;
}

.flex-grow {
    flex-grow: 1;
}

.font-weight-light {
    font-family: $type-1;
    font-weight: 300;
}

.font-weight-bold {
    font-family: $type-1;
    font-weight: 700;
}

.font-weight-normal {
    font-family: $type-1;
    font-weight: 400;
}

.cursor-pointer {
    cursor: pointer;
}

.image-grouped {
    display: flex;
    .text-avatar,
    img {
        @extend .img-md;
        border-radius: 100%;
        margin-left: -10px;
        z-index: 0;
        border: 4px solid $card-bg;
        transform: scale(1);
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-property: "box-shadow", "z-index", "transform", "border-width";
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            z-index: 1;
            box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
            transform: scale(1.05);
            border-width: 0;
        }
    }
    .text-avatar {
        color: theme-color(primary);
        font-size: 11px;
        font-weight: 600;
    }
}

.text-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.dot-indicator {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.img-avatar {
    display: inline-block!important;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    &.img-avatar48 {
        width: 48px;
        height: 48px;
    }
    &.img-avatar-thumb {
        margin: .25rem;
        box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, .3);
    }
}

.font-weight-light {
    font-family: $type-1;
    font-weight: 300;
}

.font-weight-medium {
    font-family: $type-1;
    font-weight: 500;
}

.aligner-wrapper {
    position: relative;
    .absolute {
        position: absolute;
        &.absolute-center {
            top: 50%;
            transform: translateY(-50%);
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
        }
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        &.bottom {
            bottom: 0;
        }
        &.top {
            top: 0;
        }
    }
}

.v-strock-2 {
    width: 3px;
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 100%;
    }
}

.status-indicator {
    border-width: 2px;
    border-style: solid;
    border-color: #ffaf00;
    border-radius: 100%;
    display: inline-block;
    height: 8px;
    width: 8px;
    &.online {
        border-color: theme-color(success);
    }
    &.offline {
        border-color: theme-color(primary);
    }
    &.away {
        border-color: theme-color(warning);
    }
}