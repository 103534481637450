@import '~bootstrap-icons/font/bootstrap-icons.scss';
@font-face {
    font-family: "Bootstrap Icons";
    src: url("../../public/fonts/vendor/bootstrap-icons/bootstrap-icons.woff2") format("woff2"), url("../../public/fonts/vendor/bootstrap-icons/bootstrap-icons.woff") format("woff");
}


/* Navbar */

.navbar {
    font-family: $type-1;
    transition: left $action-transition-duration $action-transition-timing-function, width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: left $action-transition-duration $action-transition-timing-function, width $action-transition-duration $action-transition-timing-function;
    -moz-transition: left $action-transition-duration $action-transition-timing-function, width $action-transition-duration $action-transition-timing-function;
    -ms-transition: left $action-transition-duration $action-transition-timing-function, width $action-transition-duration $action-transition-timing-function;
    background: $navbar-bg-2;
    .navbar-brand-wrapper {
        transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
        -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
        -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
        -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
        background: $sidebar-dark-bg;
        .sidebar-dark & {
            background: $sidebar-dark-bg;
        }
        width: $sidebar-width-lg;
        height: $navbar-height;
        .navbar-brand {
            color: $white;
            font-size: 1.5rem;
            line-height: 48px;
            margin-right: 0;
            padding: 0.25rem 0;
            display: flex;
            &:active,
            &:focus,
            &:hover {
                color: lighten;
            }
            img {
                width: calc(#{$sidebar-width-lg} - 205px);
                max-width: 100%;
                height: auto;
                margin: auto;
                vertical-align: middle;
            }
        }
        .brand-logo-mini {
            display: none;
            img {
                width: calc(#{$sidebar-width-icon} - 50px);
                max-width: 100%;
                height: auto;
                margin: auto;
            }
        }
        @media (max-width: 991px) {
            .brand-logo {
                display: none;
            }
            .brand-logo-mini {
                display: inline-block;
            }
        }
    }
    .navbar-menu-wrapper-large {
        transition: width $action-transition-duration $action-transition-timing-function;
        -webkit-transition: width $action-transition-duration $action-transition-timing-function;
        -moz-transition: width $action-transition-duration $action-transition-timing-function;
        -ms-transition: width $action-transition-duration $action-transition-timing-function;
        color: $navbar-menu-color;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        height: $navbar-height;
        @media (max-width: 991px) {
            // width: 100%;
            padding-left: 11px;
        }
    }
    .navbar-menu-wrapper {
        transition: width $action-transition-duration $action-transition-timing-function;
        -webkit-transition: width $action-transition-duration $action-transition-timing-function;
        -moz-transition: width $action-transition-duration $action-transition-timing-function;
        -ms-transition: width $action-transition-duration $action-transition-timing-function;
        color: $navbar-menu-color;
        padding-left: 15px;
        padding-right: 15px;
        width: calc(100% - #{$sidebar-width-lg});
        height: $navbar-height;
        @media (max-width: 991px) {
            // width: 100%;
            padding-left: 11px;
        }
        .navbar-toggler {
            border: 0;
            color: $navbar-toggler-color;
            margin-right: 0;
            &:not(.navbar-toggler-right) {
                @media (max-width: 1024px) {
                    display: none;
                }
            }
        }
        .navbar-text {
            font-size: $default-font-size;
        }
        .navbar-nav {
            flex-direction: row;
            align-items: stretch;
            .nav-item {
                display: flex;
                align-items: center;
                padding-left: 1rem;
                padding-right: 1rem;
                line-height: 1;
                @media (max-width: 767px) {
                    padding-right: 0.5rem;
                    padding-left: 0.5rem;
                }
                .nav-link {
                    color: darken($sidebar-light-menu-color, 5%);
                    font-size: $navbar-font-size;
                    i {
                        font-size: $navbar-icon-font-size;
                        color: $navbar-menu-color;
                    }
                    .nav-profile-img {
                        height: 34px;
                        width: 34px;
                        border-radius: 50%;
                    }
                    .profile-text {
                        margin-right: 15px;
                    }
                }
                &.dropdown {
                    .dropdown-toggle {
                        padding: 0;
                        font-weight: normal;
                        span {
                            &.menu-arrow {
                                margin-left: auto;
                                margin-right: 0;
                                transition-duration: 0.2s;
                                transition-property: transform;
                                transition-timing-function: ease-in;
                                &:before {
                                    content: "\F231";
                                    font-family: "Bootstrap Icons";
                                    font-size: 18px;
                                    line-height: 1;
                                    font-style: normal;
                                    vertical-align: middle;
                                    color: $sidebar-menu-color;
                                }
                            }
                        }
                        &.bg-transparent {
                            background: transparent;
                            border: none;
                        }
                        &[aria-expanded="true"] {
                            //background: $sidebar-light-menu-active-bg;
                            span {
                                &.menu-arrow {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                    .badge {
                        margin-left: auto;
                    }
                    .count-indicator {
                        position: relative;
                        border-radius: 100%;
                        text-align: center;
                        margin-right: 0;
                        i {
                            margin-right: 0;
                            vertical-align: middle;
                        }
                        .count {
                            position: absolute;
                            left: 50%;
                            width: 16px;
                            height: 16px;
                            border-radius: 100%;
                            top: -1px;
                            font-size: 11px;
                            color: $white;
                            background: $navbar-count-indicator-bg;
                            padding-top: 2px;
                        }
                        &:after {
                            display: none;
                        }
                        .count-varient1 {
                            background: theme-color(warning);
                        }
                        .count-varient2 {
                            background: theme-color(success);
                        }
                    }
                    i {
                        margin-right: 0.5rem;
                        vertical-align: middle;
                        .rtl & {
                            margin-left: 0.5rem;
                            margin-right: 0;
                        }
                    }
                    .navbar-dropdown {
                        font-size: 0.9rem;
                        margin-top: 0;
                        position: absolute;
                        top: $navbar-height;
                        cursor: pointer;
                        left: auto;
                        right: 0;
                        .rtl & {
                            left: 0;
                            right: auto;
                        }
                        .navbar-nav-right & {
                            right: 0;
                            left: auto;
                            .rtl & {
                                right: auto;
                                left: 0;
                            }
                        }
                        &.dropdown-left {
                            left: 0;
                            right: auto;
                        }
                        min-width: 100%;
                        -webkit-box-shadow: 0px 0px 13px -3px rgba(0,
                        0,
                        0,
                        0.1);
                        -moz-box-shadow: 0px 0px 13px -3px rgba(0,
                        0,
                        0,
                        0.1);
                        box-shadow: 0px 0px 13px -3px rgba(0,
                        0,
                        0,
                        0.1);
                        @include border-radius(4px);
                        @media (max-width: 991px) {
                            right: -85px;
                        }
                        border: 1px solid rgba(182,
                        182,
                        182,
                        0.1);
                        padding: 0;
                        @include border-radius(0);
                        overflow: hidden;
                        .dropdown-item {
                            @extend .d-flex;
                            @extend .align-items-center;
                            margin-bottom: 0;
                            padding: 7px 25px;
                            line-height: 1;
                            font-size: 14px;
                            &.text-small {
                                font-size: 12px;
                            }
                            i {
                                font-size: 24px;
                            }
                            .ellipsis {
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .preview-icon {
                                width: 40px;
                                height: 40px;
                            }
                            .small-text {
                                font-size: 0.75rem;
                            }
                        }
                        .dropdown-divider {
                            margin: 0;
                        }
                        &.navbar-dropdown-large {
                            width: 300px;
                            @media (max-width: 767px) {
                                width: 100%;
                            }
                            .dropdown-item {
                                white-space: normal;
                                &:nth-child(2n) {
                                    background: $navbar-item-light-bg;
                                }
                            }
                        }
                    }
                    @media (max-width: 767px) {
                        position: static;
                        .navbar-dropdown,
                        .navbar-dropdown-large {
                            margin: auto;
                            left: 20px;
                            right: 20px;
                            top: $navbar-height;
                        }
                    }
                    @media (max-width: 991px) {
                        .navbar-dropdown {
                            // min-width: 320px;
                            min-width: calc(100% - 40px);
                        }
                    }
                }
                &.nav-search {
                    .input-group {
                        width: 260px;
                        .input-group-append {
                            background: transparent;
                        }
                        .form-control {
                            background: $navbar-item-bg;
                            border: 0;
                            color: $white;
                            padding: 0.5rem 1rem;
                            border-top-left-radius: 50px;
                            border-bottom-left-radius: 50px;
                            height: auto;
                            .rtl & {
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                border-top-right-radius: 50px;
                                border-bottom-right-radius: 50px;
                            }
                        }
                        .input-group-text {
                            background: $navbar-item-bg;
                            border: 0;
                            color: $white;
                            padding: 0.5rem 1rem;
                            border-top-right-radius: 50px;
                            border-bottom-right-radius: 50px;
                            .rtl & {
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 0px;
                                border-top-left-radius: 50px;
                                border-bottom-left-radius: 50px;
                            }
                        }
                    }
                }
                &.nav-profile {
                    .nav-link {
                        .profile-name {
                            @media (max-width: 767px) {
                                display: none;
                            }
                        }
                    }
                }
                @media (max-width: 576px) {
                    border: none;
                }
            }
            &.navbar-nav-right {
                @extend .align-self-stretch;
                @extend .align-items-stretch;
                .nav-item {
                    @extend .d-flex;
                    @extend .align-items-center;
                }
                @media (min-width: 992px) {
                    margin-left: auto;
                    .nav-item {
                        .dropdown {
                            .dropdown-toggle {
                                &:after {
                                    content: "\F231";
                                    display: inline-block;
                                    font: normal normal normal 24px/1 "Bootstrap Icons";
                                    font-size: inherit;
                                    text-rendering: auto;
                                    line-height: inherit;
                                    -webkit-font-smoothing: antialiased;
                                    border: 0;
                                    vertical-align: inherit;
                                }
                                &.toggle-arrow-hide {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .rtl & {
                        margin-left: 0;
                        margin-right: auto;
                    }
                }
            }
            &.header-links {
                height: $navbar-height;
                padding-left: 2%;
                .nav-item {
                    margin: 0;
                    padding: 0;
                    .nav-link {
                        height: $navbar-height;
                        font-size: $navbar-font-size;
                        padding: 16px 25px;
                        display: flex;
                        align-items: center;
                        i {
                            margin-right: 10px;
                            font-size: 21px;
                            .rtl & {
                                margin-right: 0;
                                margin-left: 10px;
                            }
                        }
                    }
                    &.active {
                        background: rgba($white, 0.13);
                    }
                }
            }
        }
    }
    //navbar color variations
    &.navbar-dark {
        background: theme-color(dark);
        .navbar-toggler {
            color: $white;
        }
        .count {
            border-color: theme-color(dark);
        }
    }
    &.navbar-primary {
        background: theme-color(primary);
        .navbar-menu-wrapper {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &.count-indicator {
                            .count {
                                background: theme-color(danger);
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-success {
        background: theme-color(success);
        .navbar-menu-wrapper {
            input {
                color: $white;
                .placeholder {
                    color: inherit;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &.count-indicator {
                            .count {
                                background: dark(theme-color(success), 30%);
                                color: $white;
                                border-color: theme-color(success);
                                &.bg-success {
                                    background: dark(theme-color(success), 20%) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-danger {
        background: theme-color(danger);
        .navbar-menu-wrapper {
            input {
                color: $white;
                .placeholder {
                    color: inherit;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &.count-indicator {
                            .count {
                                background: dark(theme-color(danger), 30%);
                                color: $white;
                                border-color: theme-color(danger);
                                &.bg-danger {
                                    background: dark(theme-color(danger), 20%) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-warning {
        background: theme-color(warning);
        .navbar-menu-wrapper {
            input {
                color: $white;
                .placeholder {
                    color: inherit;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &.count-indicator {
                            .count {
                                background: dark(theme-color(warning), 30%);
                                color: $white;
                                border-color: theme-color(warning);
                                &.bg-warning {
                                    background: dark(theme-color(warning), 20%) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-pink {
        background: color(pink);
        a {
            color: $white;
        }
        .navbar-toggler {
            color: $white;
        }
        .navbar-brand-wrapper {
            .navbar-brand {
                color: $white;
            }
        }
        .navbar-menu-wrapper {
            input {
                color: $white;
                .placeholder {
                    color: inherit;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: $white;
                        &.count-indicator {
                            .count {
                                background: dark(color(pink), 30%);
                                color: $white;
                                border-color: color(pink);
                                &.bg-pink {
                                    background: dark(color(pink), 20%) !important;
                                }
                            }
                        }
                    }
                    &.dropdown {
                        .navbar-dropdown {
                            .dropdown-item {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-info {
        background: theme-color(info);
        a {
            color: $white;
        }
        .navbar-toggler {
            color: $white;
        }
        .navbar-menu-wrapper {
            input {
                color: $white;
                .placeholder {
                    color: inherit;
                }
            }
            .navbar-nav {
                .nav-item {
                    color: $white;
                    .nav-link {
                        &.count-indicator {
                            .count {
                                background: dark(theme-color(info), 30%);
                                color: $white;
                                border-color: theme-color(info);
                                &.bg-info {
                                    background: dark(theme-color(info), 20%) !important;
                                }
                            }
                        }
                    }
                    &.dropdown {
                        .navbar-dropdown {
                            .dropdown-item {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-danger,
    &.navbar-dark,
    &.navbar-primary,
    &.navbar-success,
    &.navbar-warning {
        .navbar-brand-wrapper {
            .navbar-brand {
                color: $white;
            }
        }
        .navbar-menu-wrapper {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: $white;
                    }
                }
            }
        }
        @extend .navbar-dark;
    }
    @media (max-width: 991px) {
        left: 0;
        padding: 0;
    }
    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0;
        padding-top: 0;
    }
}

@media (max-width:991px) {
    .navbar {
        flex-direction: row;
        .navbar-menu-wrapper {
            .navbar-brand {
                min-width: 50px;
                padding: 10px;
                &.brand-logo {
                    display: none;
                }
                &.brand-logo-mini {
                    display: inline-block;
                }
            }
        }
    }
    .navbar-collapse {
        display: flex;
        margin-top: 0.5rem;
    }
}

@media (max-width:768px) {
    .navbar {
        .navbar-brand-wrapper {
            width: 60px;
            .brand-logo-mini {
                padding-top: 0px;
            }
        }
        .navbar-menu-wrapper {
            width: calc(100% - 60px);
        }
    }
}

@media (max-width:991px) {
    .navbar {
        .navbar-brand-wrapper {
            width: 100px;
            .brand-logo-mini {
                padding-top: 0px;
                font-size: 1em;
                line-height: 60px;
            }
        }
        .navbar-menu-wrapper {
            width: calc(100% - 100px);
        }
    }
}
