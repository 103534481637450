// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
@import 'variables.global';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
//function
@import 'functions';
//mixins
//Styles
@import 'utilities';
@import 'misc';
// Layouts
@import 'navbar';
@import 'sidebar';
@import 'footer';
@import 'layout';
