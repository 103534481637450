// Body
$body-bg: #f8fafc;
// Typography
$font-family-sans-serif: 'Nunito',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$pagination-border-width: 3px;
$pagination-margin-start: ($pagination-border-width * -1) !default;
$table-border-width: 1px;
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem 1rem;
    color: inherit;
}


button.close {
    background-color: transparent;
    border: 0;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: .5;
}

.table-bordered thead th,
.table-bordered thead td,
.table thead th {
    border-bottom: 0px!important;
}

.img-logo {
    width: 300px;
    height: 300px;
}
