/* Layouts */

.bg-color {
  background-color: #1A202C;
}

.navbar {
    &.fixed-top {
        +.page-body-wrapper {
            padding-top: $navbar-height;
        }
    }
}

// Sidebar Icon Only
.sidebar-icon-only {
    @media (min-width: 992px) {
        .navbar {
            .navbar-brand-wrapper {
                width: $sidebar-width-icon;
                .brand-logo {
                    display: none;
                }
                .brand-logo-mini {
                    display: flex;
                    font-size: 0.85em;
                }
            }
            .navbar-menu-wrapper {
                width: calc(100% - #{$sidebar-width-icon});
            }
        }
        .sidebar {
            width: $sidebar-width-icon;
            .sidebar-brand-wrapper {
                width: $sidebar-width-icon;
                .brand-logo {
                    display: none;
                }
                .brand-logo-mini {
                    display: inline-block;
                }
            }
            .nav {
                overflow: visible;
                .nav-item {
                    position: relative;
                    padding: 0;
                    .nav-link {
                        display: block;
                        text-align: center;
                        padding: 16px 16px;
                        .menu-title,
                        .badge,
                        .menu-sub-title {
                            display: none;
                        }
                        .menu-title {
                            @include border-radius(0 5px 5px 0px);
                            @at-root #{selector-append(".rtl", &)} {
                                @include border-radius(5px 0 0 5px);
                            }
                        }
                        i,
                        span {
                            &.menu-icon {
                                margin-right: 0;
                                margin-left: 0;
                            }
                            &.menu-arrow {
                                display: none;
                            }
                        }
                        &[aria-expanded] {
                            .menu-title {
                                @include border-radius(0 5px 0 0px);
                                @at-root #{selector-append(".rtl", &)} {
                                    @include border-radius(5px 0 0 0);
                                }
                            }
                        }
                    }
                    &.nav-profile {
                        display: none;
                    }
                    &.nav-category {
                        display: none;
                    }
                    &.nav-doc {
                        margin: 0;
                        i {
                            display: block;
                        }
                    }
                    .collapse {
                        display: none;
                    }
                    &.hover-open {
                        .nav-link {
                            .menu-title {
                                display: flex;
                                align-items: center;
                                background: $sidebar-light-menu-hover-bg;
                                @at-root #{selector-append(".sidebar-dark", &)} {
                                    background: $sidebar-dark-menu-active-bg;
                                }
                                padding: 0.5rem 1.6rem;
                                left: $sidebar-width-icon;
                                position: absolute;
                                text-align: left;
                                top: 0;
                                bottom: 0;
                                width: $icon-only-collapse-width;
                                z-index: 10000;
                                line-height: 1.8;
                                @at-root #{selector-append(".rtl", &)} {
                                    left: auto;
                                    right: $sidebar-width-icon;
                                    text-align: left;
                                }
                                @at-root #{selector-append(".sidebar-dark", &)} {
                                    color: $white;
                                }
                                &:after {
                                    display: none;
                                }
                            }
                        }
                        .collapse,
                        .collapsing {
                            display: block;
                            padding: 0rem 0rem;
                            background: $sidebar-light-menu-hover-bg;
                            @include border-radius(0 0 5px 0);
                            @at-root #{selector-append(".sidebar-dark", &)} {
                                background: $sidebar-dark-bg;
                            }
                            position: absolute;
                            left: $sidebar-width-icon;
                            width: $icon-only-collapse-width;
                            @at-root #{selector-append(".rtl", &)} {
                                left: auto;
                                right: $sidebar-width-icon;
                                @include border-radius(0 0 0 5px);
                            }
                        }
                    }
                }
                &.sub-menu {
                    padding: $sidebar-icon-only-submenu-padding;
                    .nav-item {
                        padding: 0 1.25rem 0 1.25rem;
                        .nav-link {
                            text-align: left;
                            padding-left: 20px;
                        }
                    }
                    @at-root #{selector-append(".rtl", &)} {
                        .nav-item {
                            margin-right: auto;
                            margin-left: 0;
                            .nav-link {
                                text-align: right;
                                &:before {
                                    left: 0;
                                    right: unset;
                                }
                            }
                        }
                    }
                }
            }
            .sidebar-actions {
                display: none;
            }
        }
        .page-body-wrapper {
            transition: all $action-transition-duration $action-transition-timing-function;
            -webkit-transition: all $action-transition-duration $action-transition-timing-function;
            -moz-transition: all $action-transition-duration $action-transition-timing-function;
            -ms-transition: all $action-transition-duration $action-transition-timing-function;
        }
        .main-panel {
            width: calc(100% - #{$sidebar-width-icon});
        }
    }
}

//Fixed sidebar
.sidebar-fixed {
    @media (min-width: 992px) {
        .sidebar {
            position: fixed;
            max-height: auto;
            .nav {
                max-height: calc(100vh - #{$navbar-height});
                overflow: auto;
                position: relative;
                &.sub-menu {
                    max-height: none;
                }
            }
        }
        .page-body-wrapper {
            width: 100%;
            margin-left: $sidebar-width-lg;
        }
        &.sidebar-icon-only {
            .page-body-wrapper {
                margin-left: $sidebar-width-icon;
            }
        }
    }
}

//RTL layout
.rtl {
    direction: rtl;
    text-align: right;
    .sidebar {
        .nav {
            padding-right: 0;
            .nav-item {
                .nav-link {
                    text-align: middle;
                }
            }
        }
    }
    .page-body-wrapper {
        transition: all $action-transition-duration $action-transition-timing-function;
        -webkit-transition: all $action-transition-duration $action-transition-timing-function;
        -moz-transition: all $action-transition-duration $action-transition-timing-function;
        -ms-transition: all $action-transition-duration $action-transition-timing-function;
    }
    .navbar {
        transition: all $action-transition-duration $action-transition-timing-function;
        -webkit-transition: all $action-transition-duration $action-transition-timing-function;
        -moz-transition: all $action-transition-duration $action-transition-timing-function;
        -ms-transition: all $action-transition-duration $action-transition-timing-function;
        right: $sidebar-width-lg;
        @media (max-width: 900px) {
            right: 0;
        }
    }
    .product-chart-wrapper,
    .settings-panel .tab-content .tab-pane .scroll-wrapper,
    .sidebar-fixed .nav,
    .table-responsive,
    ul.chats {
        &::-webkit-scrollbar {
            width: 0.5em;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }
    &.sidebar-icon-only {
        .navbar {
            right: $sidebar-width-icon;
            left: 0;
        }
    }
}

.half-bg-dark {
    background: $sidebar-dark-bg;
}
